<template>
  <div
    class="robotMessageView"
    v-scroll="{
      distance: 100,
      onTop: _onTop,
      onBottom: _onBottom,
      listenerFn: _listenerFn,
    }"
    ref="robotView"
  >
    <div
      v-for="(message, index) in currentMessage()"
      :key="message.id"
      :id="message.id"
      :ref="message.sendTime + index"
    >
      <imelink-message-text
        :message-id="message.id"
        :message-index="index"
      ></imelink-message-text>
    </div>
  </div>
</template>
<script>
import UUID from "uuidjs";
import { send, listener } from "@/components/business/chat/server";
// 文本
import ImelinkMessageText from "../message/text";
import { getBrowserInfo } from "@/utils";
import { isString } from "utils/validate";
import Bus from "@/utils/bus.js";
export default {
  name: "RobotMessageView",
  inject: ["control"],
  components: {
    ImelinkMessageText,
  },
  data() {
    return {
      mounted: false,
      // 消息发送计时器集合
      timeoutTimer: {},
      // 超时时间
      timeout: 8000,
      replyTimer: null,
      replyTimeout: 40000,
    };
  },
  computed: {
    robotInfo() {
      return this.$store.state.robot.robotInfo;
    },
  },
  async mounted() {
    this.mounted = true;
    await this.getHistoricalChatRecords();
    Bus.$on("clearMeChatReplyTimer", async () => {
      if (this.replyTimer) {
        clearTimeout(this.replyTimer);
      }
      this.replyTimer = null;
    });
    Bus.$on("mechatScrollToBottom", async () => {
      await this.forceUpdate();
    });
    Bus.$on("desMessage", (messege_id) => {
      this.$nextTick(() => {
        document.getElementById(messege_id) &&
          document.getElementById(messege_id).remove();
        this.$forceUpdate();
      });
    });
  },
  beforeDestroy() {
    if (this.replyTimer) {
      clearTimeout(this.replyTimer);
    }
    Bus.$off("desMessage");
    Bus.$off("clearMeChatReplyTimer");
    Bus.$off("mechatScrollToBottom");
  },
  methods: {
    // 获取机器人聊天记录
    getHistoricalChatRecords() {
      const contact = this.control.currentContact;
      this.control.messageAll[contact.id] = [];
      return new Promise((resolve, reject) => {
        contact.id = contact.robotId;
        this.control._emitRequestMessage({
          isSearchMode: this.control.searchMode,
          onSuccess: async (data) => {
            this.control.messageAll[contact.id] = [...data];
            if (this.control.messageAll[contact.id].length != 0) {
              const last = this.currentMessage().slice(-1)[0];
              // 如果最后一个是用户自己，则默认用户还在等待机器人回复
              if (last.fromUser.id == this.$store.getters.userInfo.id) {
                const currentTime = await this.getRealTime();
                const timeDiff = currentTime - last.sendTime;
                if (timeDiff < this.replyTimeout) {
                  await this.fillTheData();
                  this.startRecordingReplyTime(this.replyTimeout - timeDiff);
                }
              }
            } else {
              await this.origin();
            }
            await this.forceUpdate();
            resolve();
          },
          onError: () => {
            console.log("获取与机器人聊天记录失败");
          },
          contact,
        });
      });
    },
    currentMessage() {
      return this.control.getCurrentMessage("robot");
    },
    _onTop() {
      const prevScrollHeight =
        this.$refs.robotView.scrollHeight - this.$refs.robotView.scrollTop;
      this.control._emitRequestMessage({
        onSuccess: async (data) => {
          const curmsg = this.currentMessage();
          if (curmsg) {
            const existsIds = curmsg.map((item) => item.id);
            data = data.filter((item) => {
              if (!existsIds.includes(item.id)) return item;
            });
          }
          this.control.prependMessage([...data]);
          await this.$nextTick();
          if (!this.$refs.robotView) return;
          this.scrollTo(this.$refs.robotView.scrollHeight - prevScrollHeight);
        },
        onError() {
          this.state.topLoading = false;
        },
        source: "scrollToTop",
      });
    },
    _onBottom() {},
    _listenerFn() {},
    // 填写占位数据
    fillTheData() {
      return new Promise(async (resolve, reject) => {
        const sendTime = await this.getRealTime();
        const data = {
          id: "waiting",
          status: "send_succeed",
          fromUser: {
            id: "2",
            displayName: this.replaceMechati18n(this.$t("meChat")),
            avatar: this.robotInfo.robotProfilePhoto,
          },
          sendTime: sendTime + 10,
          type: "text",
          text: "",
        };
        this.control.messageAll[this.control.currentContact.id].push(data);
        await this.$forceUpdate();
        resolve();
      });
    },
    // 更新机器人消息队列
    updateMechatMessage(message) {
      const component = this.getMechatMessageComponent(message.id);
      if (!component) {
        const tree = this.control._getTreeById(message.id);
        if (tree) {
          this.control.messageAll[tree.contactId][tree.messageIndex] = {
            ...this.control.messageAll[tree.contactId][tree.messageIndex],
            ...message,
          };
        }
      } else {
        component.update(message);
      }
    },

    getMechatMessageComponent(messageId) {
      const components = this.$children;
      const com = components.find((child) => {
        return child.$attrs["message-id"] == messageId;
      });
      return com;
    },

    // 发送初始化问题
    async origin() {
      let header = {
        cmd: "25000",
        language: this.languageLocalConversion(
          localStorage.getItem("locale") || "zh-CN"
        ),
      };
      let msg = {
        code: 10001,
        type: "mechat",
        to: `${this.robotInfo.id}@melinked.com`,
        header: header,
        protocal: {
          from: "",
        },
        from:
          this.$store.getters.userInfo.id +
          `@melinked.com/web_${getBrowserInfo()}`,
        messageId: UUID.generate(),
      };
      send(msg);
    },
    // 发送问题
    send(message) {
      const userInfo = this.$store.getters.userInfo;
      let text = this.control.filterEmojiImage(message.text, "");
      const protocal = {
        name: userInfo.name || "",
        messageId: message.id,
        data: isString(text) ? text.replace(/<br\s*\/?>/gi, "\r\n") : "",
        subType: "text",
        avatar: userInfo.avatar,
      };
      const packageData = {
        code: 10001,
        type: "mechat",
        protocal,
      };
      const data = this.packageSocketMessage(packageData);
      send(data);
      this.$forceUpdate();
      this._bindAutoTimeout(message.id, async () => {
        this.changeMessageStatus(message.id, "failed");
      });
      // 监听回调
      listener(async (data, removeListener) => {
        if (data.type == "receipt" && data.messageId == message.id) {
          this._clearAutoTimeout(message.id);
          this.changeMessageStatus(message.id, "succeed");
          await this.fillTheData();
          // 开始回复超时计时
          this.startRecordingReplyTime();
          this.scrollToBottom();
          removeListener();
        }
      }, false);
    },
    // 提问后开始计时，40秒内没有回复答案，视为出错
    startRecordingReplyTime(time = this.replyTimeout) {
      this.replyTimer = setTimeout(() => {
        if (this.control.currentContact.id == 2) {
          let curmsg = this.currentMessage();
          const last = curmsg.slice(-1)[0];
          if (last && last.id == "waiting") {
            curmsg.splice(curmsg.length - 1, 1);
            clearTimeout(this.replyTimer);
            this.$forceUpdate();
          }
        }
      }, time);
    },
    // 发送后绑定发送超时计时器
    _bindAutoTimeout(messageId, callback) {
      this._clearAutoTimeout(messageId);
      this.timeoutTimer[messageId] = setTimeout(callback, this.timeout);
    },
    // 强制更新
    async forceUpdate() {
      await this.$forceUpdate();
      this.scrollToBottom();
    },
    // 发送成功后清空超时定时器
    _clearAutoTimeout(messageId) {
      this.timeoutTimer[messageId] &&
        clearTimeout(this.timeoutTimer[messageId]);
    },
    // 改变消息发送状态
    changeMessageStatus(id, status) {
      this.updateRobotMessage({ id: id, status: `send_${status}` });
      this.$forceUpdate();
    },
    // 更新消息数据
    updateRobotMessage(message) {
      const component = this.getRobotMessageComponent(message.id);
      if (!component) {
        const tree = this._getTreeById(message.id);
        if (tree) {
          this.control.messageAll[tree.contactId][tree.messageIndex] = {
            ...this.control.messageAll[tree.contactId][tree.messageIndex],
            ...message,
          };
        }
      } else {
        component.update(message);
      }
    },
    // 根据消息ID在所有本地数据中查找
    _getTreeById(messageId) {
      const messageAll = this.control.messageAll;
      for (const key of Object.keys(messageAll)) {
        let index = messageAll[key].findIndex((val) => val.id == messageId);
        if (index !== -1) {
          return {
            messageIndex: index,
            contactId: key,
            message: messageAll[key][index],
          };
        }
      }
    },
    // 获取对应消息在界面的中的元素
    getRobotMessageComponent(messageId) {
      if (!this.control.$refs.robotMessageView) return;
      const components = this.control.$refs.robotMessageView.$children;
      const com = components.find((child) => {
        return child.$attrs["message-id"] == messageId;
      });
      return com;
    },
    // 设置滚动位置
    scrollTo(val = "0px") {
      this.$refs.robotView.scrollTop = val;
    },
    // 滚动到底部
    async scrollToBottom() {
      await this.$nextTick();
      this.scrollTo(this.$refs.robotView.scrollHeight);
    },
    // 组装socket消息
    packageSocketMessage(options) {
      const { type, protocal, code } = options;
      let header = {
        isAck: "1",
        cmd: "-1",
        isReceipt: 1,
        mimeType: protocal.subType,
        language: this.languageLocalConversion(
          localStorage.getItem("locale") || "zh-CN"
        ),
      };
      let to = `${this.robotInfo.id}@melinked.com`;
      const msg = {
        code,
        to,
        header,
        type,
        protocal,
        from:
          this.$store.getters.userInfo.id +
          `@melinked.com/web_${getBrowserInfo()}`,
        messageId: protocal.messageId,
      };
      return msg;
    },
  },
};
</script>
<style lang="stylus">
@import '~styles/utils/index';

fromuser-skew = 58px;
status-skew = 22px;

.robotMessageView
  width: 100%;
  height: 100%;
  padding: 20px 15px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-light();
  position: relative;
  background: imelink-message-view-background;
  +b(imelink-message)
    display: block;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    position: relative;
    +m(origin)
      margin-bottom: 30px;
    +e(origin)
      position: absolute;
      bottom: -20px;
      right: auto;
      left: 50px;
      font-size: 12px;
      color: #52bd68;
      cursor: pointer;
      user-select: none;
    +e(fromuser)
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 8px;
      color: #999;
      position: absolute;
      top: 0;
      left: fromuser-skew;
      display: flex;
    +e(contentInnerBox)
      display flex;
      flex-flow row;
      width: 100%;
      justify-content flex-start;
    +e(contentBox)
      display flex;
      flex-flow column;
      align-items flex-end;
      width: 100%;
      justify-content center;
    +e(fromname)
      color: #333;
      padding-right: 10px;
    +e(content)
      margin: 24px 15px 0 15px;
      max-width: 80%;
      cursor: pointer;
      border-radius: imelink-bubble-radius;
      position: relative;
      +m(meChatWarn)
        background-color: imelink-bubble-self-background !important;
        position: relative !important;
        border: 1px solid #D86B1D !important;
        box-sizing: border-box;
        &:after
          box-sizing: content-box !important;
          position: absolute !important;
          top: 5px !important;
          right: -14px !important;
          border-bottom: 7px solid transparent !important;
          border-top: 7px solid transparent !important;
          border-left: 7px solid #D86B1D !important;
          border-right: 7px solid transparent !important;
          display: block !important;
          content: "" !important;
          z-index: 1 !important;
        &:before
          box-sizing: content-box !important;
          position: absolute !important;
          top: 6px !important;
          right: -12px !important;
          border-bottom: 6px solid transparent !important;
          border-top: 6px solid transparent !important;
          border-left: 6px solid imelink-bubble-self-background !important;
          border-right: 6px solid transparent !important;
          display: block !important;
          content: "" !important;
          z-index: 2 !important;
      +m(bubble)
        background: imelink-bubble-background;
        color: imelink-bubble-color;
        padding: 10px 15px;
        line-height: 24px;
        &:after
          content: '';
          position: absolute;
          left: -13px;
          top: 4px;
          width: 0;
          height: 0;
          font-size: 0;
          border: solid 8px;
          border-color: transparent;
          border-right-color: imelink-bubble-background;
    +e(status)
      top: status-skew;
      position: relative;
      height: 24px;
      width: 24px;
      line-height: 24px;
      text-align: center;
      color: #f72b03;
      font-size: 22px;
      +m(loading)
        color: #aaa;
      +m(warning)
        cursor: pointer;
        hover-tooltip();
    +m(self)
      flex-direction: row-reverse;
      +b(imelink-message)
        +e(origin)
          left: auto;
          right: 60px;
        +e(fromuser)
          left: auto;
          right: fromuser-skew;
        +e(contentInnerBox)
          display flex;
          flex-flow row;
          justify-content flex-end;
        +e(contentBox)
          display flex;
          flex-flow column;
          align-items flex-end;
          justify-content center;
        +e(allowChatTips)
          height 30px;
          display flex;
          flex-flow row;
          padding 0 10px;
          color #333333;
          font-size 14px;
          margin-top 10px;
          font-weight bold;
          margin-right 15px;
          border-radius 4px;
          background #CCCCCC;
          align-items center;
          justify-content center;
        +e(status)
          .el-loading-spinner
            right: 18px;
        +e(content)
          background: imelink-bubble-self-background !important;
          color: imelink-bubble-self-color !important;
          &:after
            border-right-color: transparent;
            border-left-color: imelink-bubble-self-background;
            left: auto;
            right: -13px;
</style>

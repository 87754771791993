var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll",
          value: {
            distance: 100,
            onTop: _vm._onTop,
            onBottom: _vm._onBottom,
            listenerFn: _vm._listenerFn
          },
          expression:
            "{\n    distance: 100,\n    onTop: _onTop,\n    onBottom: _onBottom,\n    listenerFn: _listenerFn,\n  }"
        }
      ],
      ref: "robotView",
      staticClass: "robotMessageView"
    },
    _vm._l(_vm.currentMessage(), function(message, index) {
      return _c(
        "div",
        {
          key: message.id,
          ref: message.sendTime + index,
          refInFor: true,
          attrs: { id: message.id }
        },
        [
          _c("imelink-message-text", {
            attrs: { "message-id": message.id, "message-index": index }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }